<template>
  <div class="section-content">
    <div class="primary-section-content">
      <div class="header-class">
        <hb-header :divider="false" full>
          <hb-page-header title="Direct Refunds" description="Manually approve or denay refund requests.">
          </hb-page-header>
        </hb-header>
        <hb-btn icon tooltip="Sidebar" :class="{
          'mr-3': $vuetify.breakpoint.mdAndDown && !$vuetify.breakpoint.xs,
        }" @click="showAside = !showAside" :active-by-default="true"
          :active-state-off="!showAside">mdi-table-actions-custom-1</hb-btn>
      </div>
      <v-divider></v-divider>
      <div class="main-wraper-container">

        <!-- Main Content -->
        <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs , 'mt-n3' : $vuetify.breakpoint.sm}">
        <div class="primary-section-content">
              <files-list v-if="isDirectRefunds" :key="report_key" />
              <flagged-accounts v-else-if="!isDirectRefunds" />
        </div>
    </div>

        <v-navigation-drawer v-model="showAside" :right="!$vuetify.breakpoint.xs"
          :hide-overlay="$vuetify.breakpoint.smAndUp" :clipped="!$vuetify.breakpoint.xs"
          :class="{ 'attached-drawer': !$vuetify.breakpoint.xs }" disable-resize-watcher mobile-breakpoint="600"
          class="ma-0 pa-0 py-1 hb-tertiary-navigation" :width="showAside ? '250px' : '0px'"
          :bottom="$vuetify.breakpoint.xs" :app="$vuetify.breakpoint.xs" flat>
          <v-list dense style="width: 100%">
            <v-list-item-group v-model="selection" mandatory>
              <v-list-item class="pl-5" :to="'/direct-refunds'">
                Direct Refund
              </v-list-item>
              <v-list-item class="pl-5" :onclick="flaggedAccounts" :to="'/flagged-accounts'">
                Flagged Accounts
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import FilesList from "./FilesList.vue";
import FlaggedAccounts from "./FlaggedAccounts.vue";
import { EventBus } from "../../EventBus.js";
// import HbReport from '../assets/HummingbirdReportViewer.vue';

export default {
  name: "DirectRefund",
  data() {
    return {
      showAside: false,
      report_key: 0,
    };
  },
  components: {
    FilesList,
    FlaggedAccounts
    // HbReport
  },
  async created() {
    await this.fetchData();
    EventBus.$on("refetch_data", () => this.fetchData());
  },
  destroyed() {
    EventBus.$off("refetch_data", () => this.fetchData());
  },
  methods: {
    fetchData() {
      this.report_key++;
    },
  },
  computed: {
    isDirectRefunds() {
      return this.$route.path === "/direct-refunds";
    },
  },
};
</script>
<style lang="scss" scoped>
.section-content {
  .header-class {
    display: flex;
    justify-content: space-between;
  }

  .main-wraper-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 73px);
    overflow: hidden;
  }

  .v-navigation-drawer__content a.v-list-item:not(.v-list-item--active) {
    background-color: #f9fafb !important;
    border-left: 4px solid #f9fafb;
  }

  .v-navigation-drawer__content a.v-list-item--active {
    background-color: #e2f2f3 !important;
    border-left: 4px solid #00848e;
  }
}
</style>
